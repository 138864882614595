<template>
    <div class="p-3">
        <div class="text-center">
            <el-radio-group v-model="isCompleted" @change="loadToDos(true)">
                <el-radio-button :label="null">全部</el-radio-button>
                <el-radio-button :label="false">未完成</el-radio-button>
                <el-radio-button :label="true">已完成</el-radio-button>
            </el-radio-group>
        </div>

        <el-card shadow="never" class="mt-3">
            <div class="row no-gutters">
                <div class="col">
                    <search-box v-model="key" style="width: 280px;" @search="loadToDos(true)" />
                </div>
            </div>
            <el-table :data="toDos.data" class="mt-2">
                <el-table-column prop="title" label="履约事件" sortable />
                <el-table-column prop="reminderTime" label="履约时间" sortable :formatter="$moment.sdtFormatter"
                    width="150" />
                <el-table-column prop="reminderUsers" label="提醒人员" width="100">
                    <template #default="scope">
                        {{scope.row.reminderUsers.length}} 人
                    </template>
                </el-table-column>
                <el-table-column prop="isCompletedName" label="是否完成" width="80" />
                <el-table-column label="操作" fixed="right" width="100">
                    <template #default="scope">
                        <el-button type="text" @click="showToDoDialog(scope.row)">修改</el-button>
                        <el-button type="text" @click="completeToDo(scope.row.id)">完成</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="toDos.totalRecords"
                :current-page="toDosPagination.currentPage" :page-size="toDosPagination.pageSize" class="mt-3"
                @current-change="toDosPaginationCurrentChange" />
        </el-card>

        <el-dialog :visible.sync="toDoDialogVisible" title="履约" :close-on-click-modal="false" width="720px">
            <el-form>
                <el-form-item label="标题" class="form-item-required">
                    <el-input v-model="toDo.title" placeholder="标题" />
                </el-form-item>

                <el-form-item label="内容">
                    <el-input v-model="toDo.content" type="textarea" rows="3" placeholder="内容" />
                </el-form-item>

                <el-row :gutter="8">
                    <el-col :span="12">
                        <el-form-item label="标签（逗号隔开）">
                            <el-input v-model="toDo.tags2" placeholder="标签（逗号隔开）" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="提醒时间">
                            <el-date-picker v-model="toDo.reminderTime" type="datetime" placeholder="提醒时间" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <div class="mt-3">
                <div>提醒人员</div>
                <div class="border rounded mt-2" style="padding: 2px;">
                    <el-tag v-for="reminderUser in toDo.reminderUsers" :key="reminderUser.id" type="info" closable
                        style="margin: 2px;" @close="removeReminderUser(reminderUser)">
                        {{reminderUser.name}}
                    </el-tag>
                    <el-button icon="el-icon-plus" size="small" style="margin: 2px;"
                        @click="selectRegisteredUserDialogVisible=true">
                        添加
                    </el-button>
                </div>
            </div>
            <template #footer>
                <el-button type="primary" @click="saveToDo">确定</el-button>
                <el-button @click="toDoDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>

        <select-registered-user-dialog v-model="selectRegisteredUserDialogVisible" :is-enterprise="false"
            @select="selectRegisteredUser" />
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isCompleted: null,
                key: null,
                toDos: {},
                toDosPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                toDo: {},
                toDoDialogVisible: false,
                selectRegisteredUserDialogVisible: false,
            };
        },
        methods: {
            async loadToDos(resetPage = false) {
                if (resetPage) {
                    this.toDosPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/ToDo/GetMyToDos', {
                    params: {
                        isCompleted: this.isCompleted,
                        key: this.key,
                        pageIndex: this.toDosPagination.currentPage - 1,
                        pageSize: this.toDosPagination.pageSize,
                    }
                });
                for (const toDo of response.data.data) {
                    toDo.tags2 = toDo.tags.join();
                }
                this.toDos = response.data;
            },
            toDosPaginationCurrentChange(page) {
                this.toDosPagination.currentPage = page;
                this.loadToDos();
            },
            showToDoDialog(toDo) {
                if (toDo) {
                    this.toDo = JSON.parse(JSON.stringify(toDo));
                }
                else {
                    this.toDo = { reminderUsers: [] };
                }
                this.toDoDialogVisible = true;
            },
            selectRegisteredUser(user) {
                if (this.toDo.reminderUsers.filter(p => p.id == user.id).length != 0) {
                    return;
                }

                this.toDo.reminderUsers.push({ id: user.id, name: user.name, phone: user.phone });
            },
            removeReminderUser(reminderUser) {
                let index = this.toDo.reminderUsers.indexOf(reminderUser);
                this.toDo.reminderUsers.splice(index, 1);
            },
            async saveToDo() {
                if (this.toDo.id == undefined) {
                    await this.$axios.post('/api/ToDo/AddToDo', {
                        type: 1,
                        eventId: null,
                        title: this.toDo.title,
                        content: this.toDo.content,
                        tags: this.toDo.tags2,
                        reminderTime: this.$moment.sdt(this.toDo.reminderTime),
                        reminderUsers: this.toDo.reminderUsers,
                    });
                    this.toDoDialogVisible = false;
                    this.loadToDos();
                }
                else {
                    await this.$axios.post('/api/ToDo/UpdateToDo', {
                        id: this.toDo.id,
                        title: this.toDo.title,
                        content: this.toDo.content,
                        tags: this.toDo.tags2,
                        reminderTime: this.$moment.sdt(this.toDo.reminderTime),
                        reminderUsers: this.toDo.reminderUsers,
                    });
                    this.toDoDialogVisible = false;
                    this.loadToDos();
                }
            },
            async completeToDo(id) {
                if (!confirm('确定要完成吗？')) {
                    return;
                }

                await this.$axios.post('/api/ToDo/CompleteToDo', { id: id });
                this.loadToDos();
            },
        },
        created() {
            this.loadToDos();
        },
    };
</script>